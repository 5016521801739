function getDefaultState() {
  return {
    processing: false,
    error: null,
  };
}

export default {
  state: getDefaultState(),
  mutations: {
    SET_PROCESSING: (state, payload) => {
      state.processing = payload;
    },
    SET_ERROR: (state, payload) => {
      state.error = payload;
    },
    CLEAR_ERROR: (state) => {
      state.error = null;
    },
    RESET_GENERAL_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    getProcessing(state) {
      return state.processing;
    },
    getError(state) {
      return state.error;
    },
  },
};
