import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApoloClient } from './itApolloClientFactory.js';
import { provideApolloClient } from '@vue/apollo-composable';

const grapgUri = process.env.VUE_APP_API_SERVER_PATH + 'api/graphql';

const beedigitApolloClient = createApoloClient(grapgUri, 'BEEDIGIT', true);

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  clients: {
    beedigit: beedigitApolloClient,
  },
  defaultClient: beedigitApolloClient,
});

provideApolloClient(apolloProvider.defaultClient);

function apolloClientClearCache() {
  beedigitApolloClient.cache.data.clear();
}

export { apolloProvider, apolloClientClearCache };
