var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('section', [_c('div', {
    staticClass: "row m-0 mt-0 d-lg-none"
  }, [_c('div', {
    staticClass: "col-12 p-0"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body p-0 mb-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-end"
  }, [_vm._t("breadcrumbs"), _vm._t("top-actions")], 2)])])])]), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };