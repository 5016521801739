/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./bee-base-page.vue?vue&type=template&id=3ebe4fe6&scoped=true"
import script from "./bee-base-page.vue?vue&type=script&lang=js"
export * from "./bee-base-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ebe4fe6",
  null
  
)

export default component.exports