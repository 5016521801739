export default {
  name: 'SidebarGroupsItem',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    children: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    subIsActive() {
      return this.children.some(c => c.Action.Page == this.$route.name);
    }
  }
};