export default {
  name: 'TimeZoneSelect',
  props: {
    value: {
      type: String
    },
    state: {
      type: Boolean,
      default: null
    },
    errorMessage: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    timeZone: {
      get: function () {
        return this.value || '';
      },
      set: function (v) {
        this.$emit('input', v);
      }
    },
    gmtValues() {
      return {
        EEST: 'GMT+3',
        CEST: 'GMT+2',
        EET: 'GMT+2',
        WEST: 'GMT+1',
        CET: 'GMT+1',
        BST: 'GMT+1'
      };
    },
    timeZoneOptions() {
      return Intl.supportedValuesOf('timeZone').map(t => {
        let offset = new Intl.DateTimeFormat('en-GB', {
          timeStyle: 'long',
          timeZone: t
        }).formatToParts(new Date())[6].value;
        if (this.gmtValues[offset]) {
          offset = this.gmtValues[offset];
        }
        return {
          value: t,
          label: `${t} ${offset}`,
          offset
        };
      });
    }
  }
};