import { ROUTE_NAMES, USER_ACTIONS, USER_SUBJECTS } from '@/utils/const.js';
const Api = () => import('./pages');

const moduleRoute = {
  path: '/beedigit-api',
  name: 'BeedigitApi',
  component: Api,
  meta: {
    allowAnonymous: false,
    ability: {
      subject: USER_SUBJECTS.API_KEY,
      action: USER_ACTIONS.READ,
    },
  },
};

export default (router) => {
  router.addRoute(ROUTE_NAMES.BASE_LAYOUT, moduleRoute);
};
