var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('FormVueSelect', {
    staticClass: "countries-select reversed-select",
    attrs: {
      "placeholder": _vm.$t('form.country.label'),
      "isVisiblePlaceholder": false,
      "optionsSelect": _vm.countriesOptions,
      "state": _vm.state,
      "errorMessage": _vm.errorMessage,
      "clearable": false,
      "disabled": _vm.disabled
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "clearOption": function ($event) {
        return _vm.$emit('clearOption');
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function ({
        option
      }) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "iti__flag mr-2",
          class: option.itemClass
        }), _vm._v(" " + _vm._s(option.label) + " ")])];
      }
    }, {
      key: "open-indicator",
      fn: function ({
        attributes
      }) {
        return [_c('div', {
          staticClass: "iti__flag selected__flag",
          class: _vm.selectedCountryClass
        }), _c('svg-icon', _vm._b({
          staticClass: "fill-icon",
          attrs: {
            "size": "20",
            "viewBox": "0 0 20 20",
            "name": "chevron-down"
          }
        }, 'svg-icon', attributes, false))];
      }
    }]),
    model: {
      value: _vm.country,
      callback: function ($$v) {
        _vm.country = $$v;
      },
      expression: "country"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };