export default {
  name: 'SmartOeeMarket',
  data() {
    return {
      navigationsItems: [{
        title: 'Devices',
        iconClass: 'mdi mdi-speedometer',
        fouterTo: '/market/devices'
      }, {
        title: 'Services',
        iconClass: 'mdi mdi-speedometer',
        fouterTo: '/market/services'
      }]
    };
  }
};