const IpService = {
  getCountryByIp() {
    return fetch(process.env.VUE_APP_IPAPI)
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
      });
  },
};
export default IpService;
