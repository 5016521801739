export function convertToShortGuid(deviceId) {
  const firstLetter = deviceId.substr(0, 2).toUpperCase();
  const lastLetter = deviceId.substr(deviceId.length - 4, deviceId.length).toUpperCase();

  return `${firstLetter}-${lastLetter}`;
}

export const checkFormatShortGuid = (code) => {
  const regex = /^([0-F]{2,2})-([0-F]{4,4})$/i;
  return regex.test(code);
};

export const noSpacesInInput = (evt) => {
  if (evt.charCode == 32) {
    evt.preventDefault();
  } else {
    return true;
  }
};

export const numbersOnlyAndDot = (evt) => {
  if ((evt.charCode >= 48 && evt.charCode <= 57) || evt.charCode == 46) {
    return true;
  } else {
    evt.preventDefault();
  }
};

export const numbersOnly = (evt) => {
  if (evt.charCode >= 48 && evt.charCode <= 57) {
    return true;
  } else {
    evt.preventDefault();
  }
};
