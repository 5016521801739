var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('b-modal', {
    attrs: {
      "id": _vm.modalId,
      "centered": "",
      "header-close-content": "&times;",
      "title": _vm.$t('companies.newCompany'),
      "cancel-title": _vm.$t('button.cancel.label'),
      "ok-title": _vm.$t('button.save.label'),
      "lazy": "",
      "cancel-variant": "outline-secondary"
    },
    on: {
      "show": _vm.onShow,
      "cancel": _vm.onCancel,
      "ok": function ($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    staticClass: "modal-body-content"
  }, [_c('div', {
    staticClass: "new-company-modal"
  }, [_c('b-form', {
    staticClass: "custom-input-form preview",
    attrs: {
      "aria-live": "assertive"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "text-muted display-2",
      "label": _vm.$t('form.company.label'),
      "label-for": "company-name",
      "state": _vm.validateState('company'),
      "invalid-feedback": _vm.getValidationMessage('company')
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    staticClass: "custom-form-input custom-form-input-class",
    attrs: {
      "id": "company-name",
      "name": "company-name",
      "placeholder": _vm.$t('companies.enterCompanyName')
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur('company');
      }
    },
    model: {
      value: _vm.form.company,
      callback: function ($$v) {
        _vm.$set(_vm.form, "company", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.company"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "text-muted mb-4",
    attrs: {
      "label": _vm.$t('form.country.label'),
      "label-class": "display-2 pb-2"
    }
  }, [_c('countries-select', {
    attrs: {
      "state": _vm.validateState('country'),
      "errorMessage": _vm.getValidationMessage('country')
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur('country');
      },
      "clearOption": function ($event) {
        return _vm.onBlur('country');
      }
    },
    model: {
      value: _vm.form.country,
      callback: function ($$v) {
        _vm.$set(_vm.form, "country", $$v);
      },
      expression: "form.country"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-muted mb-4",
    attrs: {
      "label": _vm.$t('form.timeZone.label'),
      "label-class": "display-2 pb-2"
    }
  }, [_c('time-zone-select', {
    attrs: {
      "state": _vm.validateState('timeZone'),
      "errorMessage": _vm.getValidationMessage('timeZone')
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur('timeZone');
      },
      "clearOption": function ($event) {
        return _vm.onBlur('timeZone');
      }
    },
    model: {
      value: _vm.form.timeZone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "timeZone", $$v);
      },
      expression: "form.timeZone"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "text-muted display-2",
      "label": _vm.$t('form.email.label'),
      "label-for": "login-email",
      "state": _vm.validateState('email'),
      "invalid-feedback": _vm.getValidationMessage('email')
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    staticClass: "custom-form-input custom-form-input-class",
    attrs: {
      "id": "login-email",
      "name": "login-email",
      "placeholder": _vm.$t('companies.enterEmail')
    },
    on: {
      "keypress": _vm.noSpacesInInput,
      "blur": function ($event) {
        return _vm.onBlur('email');
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1)], 1)], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };