import { ref, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import userInfoQuery from '../graphql/user/currentUserInfo.query.gql';

export default function useUserInfoQuery() {
  const { result: uResult, loading, error, refetch } = useQuery(userInfoQuery);

  const currentUser = ref(null);

  watchEffect(() => {
    currentUser.value = uResult.value?.currentUser;
  });

  return { result: currentUser, loading, error, refetch };
}
