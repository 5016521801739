import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import general from './store/general';

const store = new Vuex.Store({
  modules: [general],
});

export const clearStore = () => {
  store.commit('RESET_GENERAL_STATE');
};

export default store;
