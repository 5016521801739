import countriesData from '@/assets/data/countries.json';
export default {
  name: 'CountriesSelect',
  props: {
    value: {
      type: String
    },
    state: {
      type: Boolean,
      default: null
    },
    errorMessage: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    country: {
      get: function () {
        return this.value || '';
      },
      set: function (v) {
        this.$emit('input', v);
      }
    },
    countriesOptions() {
      const countries = [];
      for (let i = 0; i < countriesData.length; i++) {
        let c = countriesData[i];
        countries[i] = {
          label: c[0],
          value: c[1],
          itemClass: 'iti__' + c[1]
        };
      }
      return countries;
    },
    selectedCountryClass() {
      return this.country ? `iti__${this.country}` : 'iti__empty';
    }
  },
  methods: {
    changeCountry() {
      this.country = this.inputInstance.getSelectedCountryData().name;
    }
  }
};