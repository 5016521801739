var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.loaderClass
  }, [_vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lds-ring"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]);
}];
export { render, staticRenderFns };