var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "w-100"
  }, [_vm.breadCrumbs.length ? _c('nav', [_c('ul', {
    staticClass: "bee-breadcrumbs bee-breadcrumbs-desktop pl-0 mb-0",
    class: _vm.breadCrumbsClass
  }, _vm._l(_vm.breadCrumbs, function (crumb, i) {
    return _c('li', {
      key: i,
      staticClass: "bee-breadcrumb-item",
      class: {
        'active-bee-breadcrumb': crumb.active,
        'single-bee-breadcrumb-button': _vm.breadCrumbs.length == 1
      }
    }, [_c('b-button', {
      staticClass: "bee-breadcrumb-button",
      attrs: {
        "disabled": crumb.active,
        "variant": "transparent"
      },
      on: {
        "click": function ($event) {
          return _vm.clickOnBreadcrumb(crumb);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$te(`breadcrumbs.${crumb.key}`) ? _vm.$t(`breadcrumbs.${crumb.key}`) : crumb.key) + " ")])], 1);
  }), 0)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };