import { ApolloClient } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';
import auth from '@/plugins/auth-service';
import { i18n } from '@/plugins/i18n';
import { Observable } from 'apollo-link';
import omitDeep from 'omit-deep-lodash';
import { onError } from '@apollo/client/link/error';

export const createApoloClient = (grapgUri, schema, connectToDevTools) => {
  const httpLink = new HttpLink({
    uri: grapgUri,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await auth.getAccessToken();
    return {
      headers: {
        ...headers,
        'Accept-Language': i18n.locale,
        Authorization: `Bearer ${token}`,
        schema,
      },
    };
  });

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    // more keys like timestamps could be included here
    const keysToOmit = ['__typename'];

    const def = getMainDefinition(operation.query);
    if (def && def.operation === 'mutation') {
      operation.variables = omitDeep(operation.variables, keysToOmit);
    }
    return forward ? forward(operation) : null;
  });

  const onErrorLink = onError(({ networkError, operation, forward }) => {
    if (networkError?.statusCode === 401) {
      const redirectPromise = new Promise((resolve, reject) => {
        auth
          .getUser()
          .then((user) => {
            if (!user) {
              return resolve();
            }

            auth
              .signoutRedirect({ id_token_hint: user?.id_token })
              .then(resolve)
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });

      return new Observable((subscriber) => {
        redirectPromise.then(
          (value) => {
            if (subscriber.closed) return;
            subscriber.next(value);
            subscriber.complete();
          },
          (err) => subscriber.error(err),
        );
        return subscriber;
      }).flatMap(() => forward(operation));
    }
  });

  const apolloClientOptions = {
    link: from([cleanTypenameLink, authLink, onErrorLink, httpLink]),
    connectToDevTools,
    cache: new InMemoryCache({}),
  };

  return new ApolloClient(apolloClientOptions);
};
