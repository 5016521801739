var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', {
    ref: "sidebar",
    staticClass: "app-sidebar",
    class: {
      'sidebar-hidden': !_setup.isSidebarOpen
    }
  }, [_c('nav', {
    staticClass: "sidebar sidebar-offcanvas",
    attrs: {
      "id": "sidebar"
    }
  }, [_setup.isCompanySidebar || _setup.isProfileSidebar ? _c('div', {
    staticClass: "nav d-block overflow-hidden pb-0"
  }, [_c('b-button', {
    staticClass: "back-btn my-2 pl-4 p-0",
    attrs: {
      "variant": "link",
      "block": ""
    },
    on: {
      "click": _setup.goBack
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('svg-icon', {
    staticClass: "mr-2 back-icon",
    attrs: {
      "viewBox": "0 0 20 20",
      "name": "arrow-left",
      "size": "20",
      "variant": "primary"
    }
  }), _c('span', {
    class: {
      'd-lg-none': !_setup.isSidebarOpen
    }
  }, [_vm._v(_vm._s(_vm.$t('button.back.label')))])], 1)])], 1) : _vm._e(), _vm._l(_setup.groupedSidebarItems, function (items, key) {
    return _c('ul', {
      key: key,
      staticClass: "nav"
    }, _vm._l(items, function (item) {
      return _c('li', {
        key: item.$ID,
        staticClass: "nav-item menu-items",
        class: {
          dropdown: item.Items
        }
      }, [!item.Items ? _c(_setup.sidebarSimpleItem, {
        attrs: {
          "id": item.$ID,
          "title": _vm.$t(item.Caption),
          "icon": item.Icon,
          "icon-class": item.IconClass,
          "view-box": item.ViewBox,
          "router-to": {
            name: item.Action.Page
          }
        },
        on: {
          "click": _setup.collapseAll
        }
      }) : _vm._e(), item.Items ? _c(_setup.sidebarGroupsItem, {
        attrs: {
          "id": item.$ID,
          "title": _vm.$t(item.Caption),
          "icon": item.Icon,
          "icon-class": item.IconClass,
          "children": item.Items
        }
      }) : _vm._e()], 1);
    }), 0);
  }), _setup.isAuthenticated ? _c('div', {
    staticClass: "dropdown-container"
  }, [_c('b-dropdown', {
    ref: "profileDropdown",
    staticClass: "profile-dropdown",
    attrs: {
      "id": "profileDropdown",
      "dropright": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "user-container d-flex align-items-center"
        }, [_c('div', [_setup.userPhoto ? _c('div', [_c('img', {
          staticClass: "img-xs rounded-circle",
          attrs: {
            "src": _setup.userPhoto,
            "alt": "user-photo"
          }
        })]) : _c('div', {}, [_c(_setup.defaultPhotoSmall)], 1)]), _c('div', {
          staticClass: "user-info"
        }, [_c('div', {
          staticClass: "sidebar-profile"
        }, [_c('div', {
          staticClass: "display-2 sidebar-profile-name"
        }, [_vm._v(" " + _vm._s(_setup.userName) + " ")]), _c('div', {
          staticClass: "caption text-muted text-left"
        }, [_vm._v(" " + _vm._s(_setup.userRoleValue) + " ")])])])])];
      },
      proxy: true
    }], null, false, 58836377)
  }, [_c('SidebarProfileDropdownBody', {
    attrs: {
      "userPhoto": _setup.userPhoto,
      "userRole": _setup.userRole,
      "userName": _setup.userName
    },
    on: {
      "chooseCompanyItem": function ($event) {
        return _setup.setSidebarType(_setup.sidebarTypes.COMPANY);
      },
      "chooseProfileItem": function ($event) {
        return _setup.setSidebarType(_setup.sidebarTypes.PROFILE);
      }
    },
    scopedSlots: _vm._u([{
      key: "locale-dropdown",
      fn: function () {
        return [_c('div', {
          staticClass: "locale-container"
        }, [_c('b-dropdown', {
          ref: "localeDropdown",
          staticClass: "locale-dropdown",
          attrs: {
            "id": "localeDropdown",
            "dropright": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('svg-icon', {
                attrs: {
                  "viewBox": "0 0 20 20",
                  "name": "language",
                  "size": "20"
                }
              }), _c('div', {
                staticClass: "ml-3 display-2 text-muted"
              }, [_vm._v(" " + _vm._s(_vm.$t('languagesName')) + " ")])], 1), _c('div', [_c('svg-icon', {
                staticClass: "chevron",
                attrs: {
                  "size": "20",
                  "viewBox": "0 0 20 20",
                  "name": "chevron-right"
                }
              })], 1)])];
            },
            proxy: true
          }], null, false, 2082228166)
        }, [_c('div', {
          staticClass: "locale-dropdown-body"
        }, _vm._l(_setup.languagesOptions, function (language) {
          return _c('div', {
            key: language.value,
            staticClass: "language-container cursor-pointer display-2",
            class: [_setup.locale === language.value ? 'text-primary' : 'text-muted'],
            on: {
              "click": function ($event) {
                return _setup.setLocale(language.value);
              }
            }
          }, [_vm._v(" " + _vm._s(language.title) + " ")]);
        }), 0)])], 1)];
      },
      proxy: true
    }], null, false, 2860036850)
  })], 1)], 1) : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };