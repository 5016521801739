import { ref } from 'vue';
import { useI18n } from '@/plugins/i18n';
import { useLocalStorage } from '@vueuse/core';
import { apolloProvider } from '@/plugins/apollo';

const { locale } = useI18n();

export default function useLocale() {
  const currentLocale = ref(locale);
  const storageLocale = useLocalStorage('userLocale', 'en');

  const setLocale = async (newLocale) => {
    currentLocale.value = newLocale;
    storageLocale.value = newLocale;
    await apolloProvider.defaultClient.reFetchObservableQueries();
  };

  const restoreLocale = () => {
    currentLocale.value = storageLocale.value;
  };

  return {
    locale: currentLocale,
    setLocale,
    restoreLocale,
  };
}
