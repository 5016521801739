import useUserStore from '@/composables/useUserStore';
import { computed } from 'vue';
export default {
  __name: 'UserInfo',
  setup(__props) {
    const {
      isAuthenticated,
      userName,
      companyName,
      companyId
    } = useUserStore();
    const isDemo = computed(() => {
      return companyId.value === 'DEFLT';
    });
    return {
      __sfc: true,
      isAuthenticated,
      userName,
      companyName,
      companyId,
      isDemo
    };
  }
};