window.dataLayer = window.dataLayer || [];

const GA_MEASUREMENT_ID = process.env.VUE_APP_GA_MEASUREMENT_ID;

function gtag() {
  if (GA_MEASUREMENT_ID) {
    window.dataLayer.push(arguments);
  }
}
gtag('js', new Date());

const defaulProps = {
  send_page_view: false,
};

gtag('config', GA_MEASUREMENT_ID, defaulProps);

export const openPage = (to) => {
  gtag('event', 'page_view', {
    page_title: to.name,
    page_location: window.location.href,
    page_path: to.path,
  });
};

export const setUserId = (user_id) => {
  gtag('config', GA_MEASUREMENT_ID, { ...defaulProps, user_id });
  gtag('set', 'user_properties', {
    ite_id: user_id,
  });
};
