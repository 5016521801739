var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-container"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center py-2"
  }, [_c('div', {
    staticClass: "rounded-circle cursor-pointer",
    class: {
      'current-company': _vm.isCurrent
    },
    attrs: {
      "id": _vm.tooltipTarget
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('update-current', _vm.companyId);
      }
    }
  }, [_vm.photoUrl ? _c('div', [_c('img', {
    staticClass: "rounded-circle d-block",
    attrs: {
      "width": "28px",
      "height": "28px",
      "src": _vm.photoUrl,
      "alt": "company-photo"
    }
  })]) : _c('div', {
    staticClass: "company-circle rounded-circle d-flex align-items-center justify-content-center display-2",
    class: {
      'is-demo': _vm.isDemo
    }
  }, [_vm._v(" " + _vm._s(_vm.initials(_vm.name)) + " ")])])]), _c('b-tooltip', {
    attrs: {
      "boundary": "window",
      "container": _vm.tooltipTarget,
      "placement": "right",
      "target": _vm.tooltipTarget
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between tooltip-container"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.name))])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };