import { ROUTE_NAMES, USER_ACTIONS, USER_SUBJECTS } from '@/utils/const.js';
const GeneralSettings = () => import('./pages/GeneralSettings.vue');

const moduleRoutes = [
  {
    path: '/general-settings',
    name: 'GeneralSettingsCompany',
    component: GeneralSettings,
    meta: {
      allowAnonymous: false,
      ability: {
        subject: USER_SUBJECTS.COMPANY_SETTINGS,
        action: USER_ACTIONS.READ,
      },
    },
  },
];

export default (router) => {
  for (let route of moduleRoutes) {
    router.addRoute(ROUTE_NAMES.BASE_LAYOUT, route);
  }
};
