import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import companiesQuery from '@/graphql/companies/companies.query.gql';

export default function useCompanies() {
  const { result, loading, error } = useQuery(companiesQuery);

  const companies = computed(() => {
    return result.value?.companies;
  });

  return { result: companies, loading, error };
}
