// import { setUserId } from '@/plugins/analytics';
// import auth from '@/plugins/auth-service.js';

export default {
  name: 'App',
  data() {
    return {
      userId: null
    };
  }
  // watch: {
  //   userId() {
  //     if (this.userId) {
  //       setUserId(this.userId);
  //     }
  //   },
  //   $route: {
  //     async handler() {
  //       auth.getUser().then((user) => {
  //         if (user) {
  //           debugger;
  //           console.log('user', user);

  //           // this.userId = user.sub;
  //         }
  //       });
  //       // this.userId = await auth.getUserId();
  //     },
  //   },
  // },
};