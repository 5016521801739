import "core-js/modules/es.array.push.js";
import { ref, computed, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import useUserStore from '@/composables/useUserStore';
import useUserInfoQuery from '@/composables/useUserInfo.query.js';
import useUserSettings from '@/composables/useUserSettings.query.js';
import { useI18n } from '@/plugins/i18n';
import useAbility from '@/plugins/useAbility';
import useLocale from '@/composables/useLocale';
import useSidebar from '@/composables/useSidebar';
import sidebarGroupsItem from '@/components/sidebar/SidebarGroupsItem.vue';
import sidebarSimpleItem from '@/components/sidebar/SidebarSimpleItem.vue';
import defaultPhotoSmall from '@/assets/images/faces/default-user-small.svg';
import { useRouter } from 'vue-router/composables';
export default {
  __name: 'TheSidebar',
  setup(__props) {
    const {
      t,
      availableLocales
    } = useI18n();
    const {
      locale,
      setLocale
    } = useLocale();
    const {
      isAuthenticated,
      userRole
    } = useUserStore();
    const {
      sidebarItems,
      setSidebarType,
      sidebarTypes,
      initSidebar,
      openSidebar,
      closeSidebar,
      onCloseSidebar,
      isCompanySidebar,
      isProfileSidebar,
      isSidebarOpen
    } = useSidebar();
    const {
      can
    } = useAbility();
    const {
      result: currentUser
    } = useUserInfoQuery();
    const {
      result: userSettings
    } = useUserSettings();
    const router = useRouter();
    const ctx = getCurrentInstance();
    const isLocaleDropdownVisible = ref(false);
    const userLanguages = computed(() => {
      return userSettings.value?.languages ?? [];
    });
    const languagesOptions = computed(() => {
      return availableLocales.filter(item => userLanguages.value.includes(item)).map(item => {
        return {
          value: item,
          title: t('languagesName', item)
        };
      });
    });
    const availableSidebarItems = computed(() => {
      return sidebarItems.value?.filter(item => {
        return !item?.ability || can(item.ability.action, item.ability.subject);
      }) || [];
    });
    const groupedSidebarItems = computed(() => {
      return availableSidebarItems.value.reduce((groups, item) => {
        const group = groups[item.group] || [];
        group.push(item);
        groups[item.group] = group;
        return groups;
      }, {});
    });
    const userName = computed(() => {
      return currentUser.value?.fullName;
    });
    const userRoleValue = computed(() => {
      if (!userRole.value) {
        return '';
      }
      return t(`roles.${userRole.value}`);
    });
    const userPhoto = computed(() => {
      return currentUser.value?.photo;
    });
    const collapseAll = () => {
      var exp_element = document.getElementsByClassName('show');
      if (exp_element.length > 0) {
        var elm_id = exp_element[0].id;
        ctx.proxy.$root.$emit('bv::toggle::collapse', elm_id);
      }
    };
    const goBack = () => {
      router.push({
        name: 'Boxes'
      });
    };
    onCloseSidebar(() => {
      isLocaleDropdownVisible.value = false;
      ctx.proxy.$refs.localeDropdown?.hide();
      ctx.proxy.$refs.profileDropdown?.hide();
    });
    onMounted(() => {
      ctx.proxy.$refs.sidebar?.addEventListener('mouseenter', openSidebar);
      ctx.proxy.$refs.sidebar?.addEventListener('mouseleave', closeSidebar);
      document.querySelector('#main-container').addEventListener('click', function () {
        let sidebar = document.querySelector('#sidebar');
        if (sidebar.classList.contains('active')) {
          sidebar.classList.remove('active');
        }
      });
      initSidebar();
      ctx.proxy.$root.$on('bv::dropdown::show', bvEvent => {
        if (bvEvent.componentId === 'localeDropdown') {
          isLocaleDropdownVisible.value = true;
        }
      });
      ctx.proxy.$root.$on('bv::dropdown::hide', bvEvent => {
        if (bvEvent.componentId === 'localeDropdown') {
          isLocaleDropdownVisible.value = false;
        }
        if (isLocaleDropdownVisible.value) {
          bvEvent.preventDefault();
        }
      });
    });
    onBeforeUnmount(() => {
      ctx.proxy.$refs.sidebar?.removeEventListener('mouseenter', openSidebar);
      ctx.proxy.$refs.sidebar?.removeEventListener('mouseleave', closeSidebar);
    });
    return {
      __sfc: true,
      t,
      availableLocales,
      locale,
      setLocale,
      isAuthenticated,
      userRole,
      sidebarItems,
      setSidebarType,
      sidebarTypes,
      initSidebar,
      openSidebar,
      closeSidebar,
      onCloseSidebar,
      isCompanySidebar,
      isProfileSidebar,
      isSidebarOpen,
      can,
      currentUser,
      userSettings,
      router,
      ctx,
      isLocaleDropdownVisible,
      userLanguages,
      languagesOptions,
      availableSidebarItems,
      groupedSidebarItems,
      userName,
      userRoleValue,
      userPhoto,
      collapseAll,
      goBack,
      sidebarGroupsItem,
      sidebarSimpleItem,
      defaultPhotoSmall
    };
  }
};