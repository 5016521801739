import { ROUTE_NAMES } from '@/utils/const.js';
import { USER_ACTIONS, USER_SUBJECTS } from '@/utils/const';
const Shifts = () => import('./pages/Shifts.vue');
const Shift = () => import('./pages/Shift.vue');

const moduleRoutes = [
  {
    path: '/shifts',
    name: 'Shifts',
    component: Shifts,
    meta: {
      allowAnonymous: false,
      ability: {
        subject: USER_SUBJECTS.SHIFTS,
        action: USER_ACTIONS.READ,
      },
    },
  },
  {
    path: '/shifts/:shiftId',
    name: 'Shift',
    component: Shift,
    props: (route) => ({
      shiftId: route.params.shiftId,
      shiftDate: route.query.shiftDate,
      shift: route.query.shift,
      workCenterId: route.query.workCenterId,
    }),
    meta: {
      allowAnonymous: false,
      ability: {
        subject: USER_SUBJECTS.SHIFTS,
        action: USER_ACTIONS.READ,
      },
    },
  },
];

export default (router) => {
  for (let route of moduleRoutes) {
    router.addRoute(ROUTE_NAMES.BASE_LAYOUT, route);
  }
};
