export default {
  name: 'SmartOeeSettings',
  data() {
    return {
      navigationsItems: [{
        title: 'Profile',
        iconClass: 'mdi mdi-speedometer',
        fouterTo: '/profile'
      }, {
        title: 'Boxes',
        iconClass: 'mdi mdi-speedometer',
        fouterTo: '/settings/boxes'
      }, {
        title: 'Billing',
        iconClass: 'mdi mdi-speedometer',
        fouterTo: '/settings/billing'
      }]
    };
  }
};