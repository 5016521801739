var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('span', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'page-dropdown-' + _vm.id,
      expression: "'page-dropdown-' + id"
    }],
    staticClass: "nav-link",
    class: {
      active: _vm.subIsActive
    }
  }, [_c('span', {
    staticClass: "menu-icon"
  }, [_c('svg-icon', {
    class: _vm.iconClass,
    attrs: {
      "name": _vm.icon
    }
  })], 1), _c('span', {
    staticClass: "menu-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('i', {
    staticClass: "menu-arrow"
  })]), _c('b-collapse', {
    attrs: {
      "id": 'page-dropdown-' + _vm.id,
      "visible": _vm.subIsActive,
      "accordion": "sidebar-accordion"
    }
  }, [_c('ul', {
    staticClass: "nav flex-column sub-menu"
  }, _vm._l(_vm.children, function (childe) {
    return _c('li', {
      key: `${_vm.title}${_vm.$t(childe.Caption)}}`,
      staticClass: "nav-item"
    }, [_c('router-link', {
      staticClass: "nav-link",
      attrs: {
        "to": {
          name: childe.Action.Page
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(childe.Caption)) + " ")])], 1);
  }), 0)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };