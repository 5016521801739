import "core-js/modules/es.array.push.js";
import boxNameQuery from '@/graphql/boxinfo/boxName.gql';
export default {
  name: 'bee-breadcrumbs',
  props: {
    additionalCrumbs: {
      type: Array
    },
    breadCrumbsClass: {
      type: String
    }
  },
  apollo: {
    boxName: {
      query: boxNameQuery,
      variables() {
        return {
          deviceId: this.currentDeviceId
        };
      },
      update(data) {
        return data.boxes[0]?.workCenter?.name || '';
      },
      skip() {
        return !this.currentDeviceId;
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    currentDeviceId() {
      return this.$route.params.deviceId;
    },
    pathArray() {
      let routePathArray = this.$route.path.split('/');
      if (this.additionalCrumbs && this.additionalCrumbs.length) {
        routePathArray.push(...this.additionalCrumbs);
      }
      routePathArray.shift();
      return routePathArray;
    },
    breadCrumbs() {
      const breadCrumbs = [];
      let breadcrumb = '';
      for (let i = 0; i < this.pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${'/'}${this.pathArray[i]}`;
        breadCrumbs.push({
          href: breadcrumb,
          active: i + 1 === this.pathArray.length,
          key: this.pathArray[i] === this.currentDeviceId ? this.boxName : this.pathArray[i]
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    clickOnBreadcrumb(crumb) {
      if (this.$listeners && this.$listeners.clickOnBreadcrumb) {
        this.$emit('clickOnBreadcrumb', crumb, () => {
          this.clickOnBreadcrumbBase(crumb);
        });
        return;
      }
      this.clickOnBreadcrumbBase(crumb);
    },
    clickOnBreadcrumbBase(crumb) {
      this.$router.push({
        path: crumb.href
      });
    }
  }
};