export const validateStateForm = (form, previewMode = false) => {
  return (name) => {
    if (!(name in form)) {
      return null;
    }
    const { $dirty, $error } = form[name];

    if (previewMode) {
      return $dirty ? !$error : null;
    }

    return $dirty && $error ? false : null;
  };
};
