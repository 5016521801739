import { ROUTE_NAMES } from '@/utils/const.js';
const Profile = () => import('./pages/ProfileSettings.vue');
const Password = () => import('./pages/Password.vue');

const moduleRoutes = [
  {
    path: '/profile',
    name: 'SettingsProfile',
    component: Profile,
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: '/password',
    name: 'Password',
    component: Password,
    meta: {
      allowAnonymous: false,
    },
  },
];

export default (router) => {
  for (let route of moduleRoutes) {
    router.addRoute(ROUTE_NAMES.BASE_LAYOUT, route);
  }
};
