import { ROUTE_NAMES } from '@/utils/const.js';
import { USER_ACTIONS, USER_SUBJECTS } from '@/utils/const';
const JobOrders = () => import('./pages');

const moduleRoute = {
  path: '/job-orders',
  name: 'JobOrders',
  component: JobOrders,
  meta: {
    allowAnonymous: false,
    ability: {
      subject: USER_SUBJECTS.JOB_ORDERS,
      action: USER_ACTIONS.READ,
    },
  },
};

export default (router) => {
  router.addRoute(ROUTE_NAMES.BASE_LAYOUT, moduleRoute);
};
