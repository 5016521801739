export default {
  name: 'SidebarSimpleItem',
  props: {
    title: {
      type: String,
      required: true
    },
    routerTo: {
      type: Object
    },
    icon: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: ''
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    }
  }
};