var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', [_vm._t("default", null, {
    "isAuthenticated": _setup.isAuthenticated,
    "userName": _setup.userName,
    "companyName": _setup.companyName,
    "companyId": _setup.companyId,
    "isDemo": _setup.isDemo
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };