import router from './router';
import { i18n } from '@/plugins/i18n';

const registerModule = (name, module) => {
  if (module.router) {
    module.router(router);
    const locales = module.locales;
    if (locales) {
      Object.keys(locales).forEach((key) => {
        i18n.mergeLocaleMessage(key, locales[key]);
      });
    }
  }
};

export const registerModules = (modules) => {
  Object.keys(modules).forEach((moduleKey) => {
    const module = modules[moduleKey];
    registerModule(moduleKey, module);
  });
};
