import en from './en.json';
import uk from './uk.json';
import pl from './pl.json';
import { createLocale } from '../../utils.js';

const locales = {
  en: createLocale(en, 'jobOrders'),
  uk: createLocale(uk, 'jobOrders'),
  pl: createLocale(pl, 'jobOrders'),
};

export default locales;
