import initials from 'initials';
export default {
  name: 'SidedarCompanyItem',
  props: {
    companyId: {
      type: String
    },
    name: {
      type: String
    },
    // role: {
    //   type: String,
    // },
    photoUrl: {
      type: [String, null]
    },
    isCurrent: {
      type: Boolean
    }
  },
  computed: {
    isDemo() {
      return this.companyId === 'DEFLT';
    },
    tooltipTarget() {
      return `tooltip-${this.companyId}`;
    }
  },
  methods: {
    initials
    // getRoleName(role) {
    //   if (role === USER_ROLES.USER) {
    //     return this.$t('roles.USER');
    //   } else if (role === USER_ROLES.ADMIN) {
    //     return this.$t('roles.ADMIN');
    //   }
    //   return '-';
    // },
  }
};