import { ref, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import userSettingsQuery from '../graphql/user/userSettings.query.gql';

export default function useUserSettings() {
  const { result: uResult, loading, error } = useQuery(userSettingsQuery);

  const userSettings = ref(null);

  watchEffect(() => {
    userSettings.value = uResult.value?.userSettings;
  });

  return { result: userSettings, loading, error };
}
