import { apolloProvider } from '@/plugins/apollo';
import userAbilitiesQuery from '@/graphql/user/userAbilities.gql';

export default async function getUserAbilities() {
  try {
    const queryData = await apolloProvider.defaultClient.query({ query: userAbilitiesQuery });
    const userAbilities = queryData.data?.userAbilities;
    return userAbilities;
  } catch (e) {
    console.log(e);
    return [];
  }
}
