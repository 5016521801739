var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('FormVueSelect', {
    staticClass: "reversed-select",
    attrs: {
      "optionsSelect": _vm.timeZoneOptions,
      "placeholder": _vm.$t('companies.enterTimeZone'),
      "isVisiblePlaceholder": false,
      "state": _vm.state,
      "errorMessage": _vm.errorMessage,
      "clearable": false,
      "disabled": _vm.disabled
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "clearOption": function ($event) {
        return _vm.$emit('clearOption');
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function ({
        option
      }) {
        return [_c('span', {
          staticClass: "text-muted"
        }, [_vm._v("(" + _vm._s(option.offset) + ")")]), _c('span', {
          staticClass: "pl-2 text-white"
        }, [_vm._v(_vm._s(option.value))])];
      }
    }]),
    model: {
      value: _vm.timeZone,
      callback: function ($$v) {
        _vm.timeZone = $$v;
      },
      expression: "timeZone"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };