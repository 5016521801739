export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    size: {
      type: [String, Number],
      default: '18px'
    },
    variant: {
      type: String,
      default: 'white'
    }
  },
  data() {
    return {
      iconComponent: () => {}
    };
  },
  watch: {
    name: {
      immediate: true,
      handler() {
        this.iconComponent = () => import(`@/assets/icons/${this.name}.svg`);
      }
    }
  }
};