var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.iconComponent, {
    tag: "component",
    attrs: {
      "viewBox": _vm.viewBox,
      "width": _vm.size,
      "height": _vm.size,
      "stroke": `var(--${_vm.variant})`
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };