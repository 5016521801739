function extractValidationsKeys(validations) {
  const keys = [];

  for (const key in validations) {
    if (!key.startsWith('$')) {
      keys.push(key);
    }
  }

  return keys;
}

export const validationMessagesExtractor = (validationRules, messages) => {
  return (field) => {
    const validations = validationRules[field];

    const fieldMessages = messages[field];

    if (!validations || !validations.$dirty || !fieldMessages) {
      return '';
    }

    const keys = extractValidationsKeys(validations);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];

      if (!validations[key] || validations[key].$invalid) {
        if (fieldMessages[key]) {
          return fieldMessages[key](field);
        }
      }
    }

    for (const key in validations) {
      if (key.startsWith('$')) {
        continue;
      }
    }
    return '';
  };
};
