import BeeBreadcrumbs from '@/components/bee-base/breadcrumbs/bee-breadcrumbs.vue';
import UserInfo from '@/components/user/UserInfo.vue';
import auth from '@/plugins/auth-service';
export default {
  name: 'TheHeader',
  components: {
    BeeBreadcrumbs,
    UserInfo
  },
  data() {
    return {
      isOpenSidebarMobile: false
    };
  },
  methods: {
    signIn() {
      auth.signInRedirect();
    },
    toggleMobileSidebar() {
      document.querySelector('#sidebar').classList.toggle('active');
      this.isOpenSidebarMobile = !this.isOpenSidebarMobile;
    },
    closeMobileSidebar() {
      document.querySelector('#sidebar').classList.remove('active');
      this.isOpenSidebarMobile = false;
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (window.innerWidth < 992) {
          this.closeMobileSidebar();
        }
      }
    }
  }
};