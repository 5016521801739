var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('b-navbar', {
    staticClass: "p-0 fixed-top",
    class: {
      'no-border-bottom': _vm.isOpenSidebarMobile
    },
    attrs: {
      "id": "template-header",
      "toggleable": "lg"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between",
    class: {
      'open-sidebar-mobile': _vm.isOpenSidebarMobile
    }
  }, [_c('div', {
    staticClass: "d-flex mobile-logo-block menu-container"
  }, [_c('div', {
    staticClass: "navbar-button-wrapper d-flex flex-row justify-content-center align-items-center px-2 px-lg-0",
    attrs: {
      "data-test-id": "menu-button"
    }
  }, [_c('button', {
    staticClass: "navbar-toggler align-self-center d-lg-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleMobileSidebar();
      }
    }
  }, [_c('svg-icon', {
    staticClass: "text-muted",
    attrs: {
      "size": "24",
      "name": "menu"
    }
  })], 1)]), _c('div', {
    staticClass: "navbar-brand-wrapper d-flex flex-row align-items-center"
  }, [_c('div', [_c('router-link', {
    attrs: {
      "to": "/boxes"
    }
  }, [_c('b-img', {
    staticClass: "d-block",
    attrs: {
      "src": require('@/assets/images/logo-main.png'),
      "width": "124",
      "alt": "BeeDigit"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "navbar-menu-wrapper d-flex align-items-center"
  }, [_c('ul', {
    staticClass: "navbar-nav w-100"
  }, [_c('li', {
    staticClass: "nav-item",
    staticStyle: {
      "width": "80%"
    }
  }, [_c('div', {
    staticClass: "nav-link mt-2 mt-md-0 d-none d-lg-flex"
  }, [_c('bee-breadcrumbs')], 1)])]), _c('div', {
    staticClass: "navbar-nav"
  }, [_c('user-info', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        isAuthenticated
      }) {
        return [!isAuthenticated ? _c('div', {
          staticClass: "align-items-center justify-content-center h-100"
        }, [_c('b-button', {
          staticClass: "btn-fw",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.signIn
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('register.form.footer.loginLink')) + " ")])], 1) : _c('div', {
          staticClass: "d-lg-none px-3"
        })];
      }
    }])
  })], 1)])]), _c('div', {
    staticClass: "d-none w-100 justify-content-between flex-row align-items-center",
    class: {
      'close-sidebar-mobile': _vm.isOpenSidebarMobile
    }
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t('sidebar.menu')))]), _c('b-button', {
    staticClass: "p-0",
    attrs: {
      "variant": "transparent"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleMobileSidebar();
      }
    }
  }, [_c('svg-icon', {
    staticClass: "fill-icon",
    attrs: {
      "name": "x",
      "stroke": "#BCC0CC",
      "size": "24"
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };