import { createMongoAbility, createAliasResolver } from '@casl/ability';
import { USER_ACTIONS } from '@/utils/const';

const resolveAction = createAliasResolver({
  ['MODIFY']: [USER_ACTIONS.UPDATE, USER_ACTIONS.READ],
});

let ability = null;

export default function useAbility() {
  return {
    init(rules = []) {
      ability = createMongoAbility(rules, { resolveAction });
    },
    destroy() {
      ability = null;
    },
    can(action, subject) {
      return ability.can(action, subject);
    },
    get isAbility() {
      return Boolean(ability);
    },
  };
}
